import { CreateControllerFn } from '@wix/yoshi-flow-editor';
import { FormKind, initFormController } from '@wix/form-viewer/controller';
import { WAITLIST_FORM_ID, WAITLIST_FORM_NAMESPACE } from './constants';
import {
  ServiceType,
  type Service,
} from '@wix/ambassador-bookings-services-v2-service/types';
import {
  AppointmentWaitlistFormContext,
  AvailabilityPreferences,
} from './types';
import {
  AppointmentWaitlistFormActions,
  createAppointmentWaitlistFormActions,
} from './actions/actions';
import {
  AppointmentWaitlistFormViewModel,
  createAppointmentWaitlistFormViewModel,
} from './viewModel/viewModel';
import { AppointmentWaitlistFormAPI } from './api/AppointmentWailtlistFormAPI';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { createDummyData } from './dummyData/createDummyData';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const { setProps } = controllerConfig;
  const appointmentWaitlistFormAPI = new AppointmentWaitlistFormAPI(flowAPI);
  let context: AppointmentWaitlistFormContext;
  let availabilityPreferences: AvailabilityPreferences;
  let servicesMap: Record<string, Service> | undefined;
  let viewModel: AppointmentWaitlistFormViewModel;
  let actions: AppointmentWaitlistFormActions;
  let member: Member | undefined;

  const pageReady = async () => {
    if (flowAPI.environment.isEditor) {
      setProps(createDummyData());
    } else {
      controllerConfig.wixCodeApi.user.onLogin(async () => {
        await updateMember();
        updateWidgetProps();
      });

      await initFormController(flowAPI, {
        formId: WAITLIST_FORM_ID,
        namespace: WAITLIST_FORM_NAMESPACE,
        formKind: FormKind.EXTENSION,
      });
    }
  };

  const updateWidgetProps = () => {
    context = {
      availabilityPreferences,
      servicesMap,
      appointmentWaitlistFormAPI,
      flowAPI,
      member,
    };
    viewModel = createAppointmentWaitlistFormViewModel({
      context,
    });
    actions = createAppointmentWaitlistFormActions({
      controllerConfig,
      flowAPI,
      viewModel,
    });

    setProps({ viewModel: { ...viewModel }, actions });
  };

  const updateServicesMap: PotentialWidgetPropsUpdateNeededFN = async () => {
    const serviceIds = availabilityPreferences.services.map((s) => s.serviceId);
    const shouldFetchServices =
      !servicesMap || !serviceIds.every((id) => servicesMap?.[id]);

    if (!flowAPI.environment.isEditor && shouldFetchServices) {
      try {
        const {
          data: { services },
        } = await appointmentWaitlistFormAPI.queryServicesByIds({
          ids: serviceIds,
        });

        if (!servicesMap) {
          servicesMap = {};
        }

        services!.forEach((service) => {
          servicesMap![service.id!] = service;
        });
      } catch (error) {
        console.error('Failed to fetch services', error);
        servicesMap = {};
      }

      return { shouldUpdateWidgetProps: true };
    }

    return { shouldUpdateWidgetProps: false };
  };

  const updateMember: PotentialWidgetPropsUpdateNeededFN = async () => {
    const { id, loggedIn } =
      flowAPI.controllerConfig.wixCodeApi.user.currentUser;
    if (member || !loggedIn) {
      return { shouldUpdateWidgetProps: false };
    }

    try {
      member = await appointmentWaitlistFormAPI.getMemberDetails({
        id,
      });
      return { shouldUpdateWidgetProps: true };
    } catch (error) {
      console.error('Failed to fetch member details', error);
      return { shouldUpdateWidgetProps: false };
    }
  };

  const onAvailabilityPreferencesChange = async (
    value: AvailabilityPreferences,
  ) => {
    console.log(value);
    availabilityPreferences = value;

    updateWidgetProps();

    const withUpdateWidgetProps = (fn: PotentialWidgetPropsUpdateNeededFN) => {
      fn().then(({ shouldUpdateWidgetProps }) => {
        if (shouldUpdateWidgetProps) {
          updateWidgetProps();
        }
      });
    };

    withUpdateWidgetProps(updateServicesMap);
    withUpdateWidgetProps(updateMember);
  };

  return {
    pageReady,
    exports: () => ({
      set availabilityPreferences(value: AvailabilityPreferences) {
        if (
          value?.services?.every(
            (service) => service.serviceType === ServiceType.APPOINTMENT,
          )
        ) {
          onAvailabilityPreferencesChange(value);
        }
      },
    }),
  };
};

type PotentialWidgetPropsUpdateNeededFN = () => Promise<{
  shouldUpdateWidgetProps: boolean;
}>;

export default createController;
